<template>
  <div>
    <header class="site-header flex justify--space-between flex--items-center">
      <div class="logos--left">
        <img src="/img/logo.png" alt="Toyota logo" />
        <img src="/img/toyota.png" alt="Toyota Logo" class="toyota-logo" />
      </div>
    </header>
    <div class="login">
      <b-card class="container mb-4 card">
        <b-card-body class="content">
          <form class="login-form" @submit.prevent="handleSubmit">
            <h3 class="title form-header">Sign In</h3>
            <p class="text-danger" v-if="errorMessage">{{ errorMessage }}</p>
            <div class="form-group">
              <label>Email address</label>
              <b-form-input
                type="email"
                v-model="login.username"
                size="md"
                required
              ></b-form-input>
            </div>
            <div class="form-group">
              <label>Password</label>
              <b-form-input
                type="password"
                v-model="login.password"
                size="md"
                required
              ></b-form-input>
            </div>
            <p class="forgot-password text-right mt-2 mb-4">
              <router-link to="/forgot-password">Forgot password ?</router-link>
            </p>
            <div class="text-center">
              <b-button :disabled="loading" pill variant="danger" type="submit">
                {{ buttonText }}
              </b-button>
            </div>
          </form>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>

<script>
import AccountService from "../../service/account";
import { storeData } from "../../utils/storage";
export default {
  computed: {
    buttonText() {
      return this.loading ? "Please wait" : "Sign In";
    }
  },
  data() {
    return {
      loading: false,
      login: {},
      errorMessage: ""
    };
  },
  methods: {
    async handleSubmit() {
      this.loading = true;
      try {
        const axios = require("axios");

        let config = {
          method: "get",
          maxBodyLength: Infinity,
          url:
            "https://platedetect.softalliance.com/api/pdpay/search-bills?numberPlate=MUS174CP",
          headers: {}
        };

        axios
          .request(config)
          .then(response => {
            console.log(JSON.stringify(response.data));
          })
          .catch(error => {
            console.log(error);
          });

        console.log(this.login);
        //this.apiBaseUrl +
        const response = await AccountService.processLogin(this.login);
        const objResponse = response.Data || response.data;
        if (!this.validateResponse(objResponse) && objResponse.Data != null) {
          storeData("userInfo", JSON.stringify(objResponse.Data));
          window.location = "/AccountStatus";
        } else {
          this.errorMessage = "Invalid Email Address/Password";
        }
      } catch (error) {
        console.error(error);
      }

      this.loading = false;
    },
    validateResponse(response) {
      return !response || response === null || response == null;
    }
  }
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,900&display=swap");

.site-header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  font-weight: bold;
  margin-top: 20px;
  min-height: 80px;
  padding: 0 20px;
  z-index: 32;
}

.site-header img {
  margin: 4px;
}

.login .content {
  padding: 48px;
  color: dimgrey;
  font-weight: bold;
}
.login .card {
  max-width: 32rem;
  max-height: 55rem;
}
.login .title {
  margin-bottom: 8px;
  font-family: "Raleway Medium", sans-serif;
}
.login button {
  padding: 8px 50px;
}
</style>
